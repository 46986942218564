.popupbox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
}

.popupbox[hidden] {
  display: none;
}

.popupbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(25px);
}

.popupbox-content {
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popupbox-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 1003;
  position: relative;

  border-radius: 3px;
  overflow: hidden;

  min-width: 0;
  max-width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  height: 100%;
}

.popupbox-titleBar {
  overflow: hidden;
  display: block;
  position: relative;

  font-weight: bold;
  font-size: 18px;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
  padding: 11px 37px 11px 24px;
  border-bottom: 1px #ccc solid;
}

.popupbox-btn--close {
  z-index: 1004;

  transition: all 0.5s;
  position: absolute;
  right: 11px;
  top: 11px;
  color: #c1c1c1;
  background: none;
  border: none;
  outline: none;
}

.popupbox[data-title='bottom'] .popupbox-content {
  order: 1;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.popupbox[data-title='bottom'] .popupbox-titleBar {
  order: 2;

  box-shadow: none;
  border-top: 1px #ccc solid;
}

.popupbox-btn--close:hover {
  color: #000;
}

.popupbox.is-active {
  opacity: 1;
  pointer-events: auto;
}
