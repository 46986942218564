@font-face {
  font-family: Moderat;
  /* IE9 Compat Modes */
  src: url('https://1716637182.rsc.cdn77.org/web/fonts/Moderat/Moderat-Regular.eot');
  /* IE6-IE */
  src:
    url('https://1716637182.rsc.cdn77.org/web/fonts/Moderat/Moderat-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* Super Modern Browsers */
      url('https://1716637182.rsc.cdn77.org/web/fonts/Moderat/Moderat-Regular.woff2')
      format('woff2'),
    /* Pretty Modern Browsers */
      url('https://1716637182.rsc.cdn77.org/web/fonts/Moderat/Moderat-Regular.woff')
      format('woff'),
    /* Safari, Android, iOS */
      url('https://1716637182.rsc.cdn77.org/web/fonts/Moderat/Moderat-Regular.ttf')
      format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: PPPangramSansRounded;
  /* IE9 Compat Modes */
  src: url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded/PPPangramSansRounded.eot');
  /* IE6-IE */
  src:
    url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded/PPPangramSansRounded.eot?#iefix')
      format('embedded-opentype'),
    /* Super Modern Browsers */
      url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded/PPPangramSansRounded.woff2')
      format('woff2'),
    /* Pretty Modern Browsers */
      url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded/PPPangramSansRounded.woff')
      format('woff'),
    /* Safari, Android, iOS */
      url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded/PPPangramSansRounded.ttf')
      format('truetype');
  font-display: fallback;
  font-weight: 400;
  ascent-override: 92%;
  descent-override: 20%;
}

@font-face {
  font-family: PPPangramSansRounded-Bold;
  /* IE9 Compat Modes */
  src: url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded-Bold/PPPangramSansRounded-Bold.eot');
  /* IE6-IE */
  src:
    url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded-Bold/PPPangramSansRounded-Bold.eot?#iefix')
      format('embedded-opentype'),
    /* Super Modern Browsers */
      url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded-Bold/PPPangramSansRounded-Bold.woff2')
      format('woff2'),
    /* Pretty Modern Browsers */
      url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded-Bold/PPPangramSansRounded-Bold.woff')
      format('woff'),
    /* Safari, Android, iOS */
      url('https://1716637182.rsc.cdn77.org/web/fonts/PPPangramSansRounded-Bold/PPPangramSansRounded-Bold.ttf')
      format('truetype');
  font-display: fallback;
  font-weight: 600;
  ascent-override: 92%;
  descent-override: 20%;
}
